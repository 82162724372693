<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row
      justify="center"
      style="pa-5"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <base-material-card
          title="IEEE Texas Power and Energy Conference"
          color="#454545"
        >
          <!-- Fills in the information below the avatar icon. pa-0 removes the padding. -->
          <v-card-text
            class="text-center pa-0"
          >
            <font
              size="4"
            >
              The IEEE Texas Power and Energy Conference (TPEC) brings together participants
              from industry and academia to present and discuss the latest technological developments
              and challenges in the power and energy industry. Electric power engineers, technical managers,
              educators and students are encouraged to participate and contribute with their knowledge to
              advance the research and development of the power and energy industry. The conference is
              completely student-run, via the IEEE PES-PELS-IAS Joint Student Chapter at Texas A&M
              University, under guidance from faculty advisors.
            </font>
          </v-card-text> <br> <br>
          <v-row>
            <!-- <a> is an anchor, and is used to hyperlink text. This one hyperlinks an email. -->
            <v-col
              cols="6"
              lg="3"
              class="text-center"
            >
              <a
                href="https://twitter.com/tpec2021"
                target="_blank"
                style="text-decoration: none"
              >
                <v-icon
                  large
                >
                  mdi-twitter
                </v-icon>
                <br>Twitter
              </a>
            </v-col>
            <!-- <a> is an anchor, and is used to hyperlink text. This one hyperlinks Google Scholar. -->
            <v-col
              cols="6"
              lg="3"
              class="text-center"
            >
              <a
                href="https://www.facebook.com/TexasPowerEnergyConf/"
                target="_blank"
                style="text-decoration: none"
              >
                <v-icon
                  large
                >
                  mdi-facebook
                </v-icon>
                <br> Facebook
              </a>
            </v-col>
            <!-- <a> is an anchor, and is used to hyperlink text. This one hyperlinks Github. -->
            <v-col
              cols="6"
              lg="3"
              class="text-center"
            >
              <a
                href="https://www.linkedin.com/company/tpec2021/"
                target="_blank"
                style="text-decoration: none"
              >
                <v-icon
                  large
                >
                  mdi-linkedin
                </v-icon>
                <br> LinkedIn
              </a>
            </v-col>
            <!-- <a> is an anchor, and is used to hyperlink text. This one hyperlinks LinkedIn. -->
            <v-col
              cols="6"
              lg="3"
              class="text-center"
            >
              <a
                href="https://www.instagram.com/tpec2021/"
                target="_blank"
                style="text-decoration: none"
              >
                <v-icon
                  large
                >
                  mdi-instagram
                </v-icon>
                <br> Instagram
              </a>
            </v-col>
          </v-row>
        </base-material-card>
        <base-material-card
          title="TPEC 2021"
          color="#454545"
        >
          <v-row>
            <v-card-text
              class="text-center"
            >
              <font
                size="4"
              >
                TPEC 2021, given the global climate, was held entirely virtually. Despite the setbacks, the conference
                was a huge success, and gave light to new ways to host a conference and still strive to maintain the
                strengths of an in-person conference. For TPEC 2021, I served as the Promotion Logistics Chair, assisting
                with social media and website development, along with the design of the TPEC 2021 magazine, a new avenue
                for presenting information to the TPEC attendees. For an overview of TPEC 2021, please visit
                <a
                  href="http://tpec.engr.tamu.edu/2021-tpec-summary/"
                  target="_blank"
                >
                  http://tpec.engr.tamu.edu/2021-tpec-summary/.
                </a>
              </font>
            </v-card-text>
          </v-row>
          <v-row>
            <v-col
              lg="6"
              cols="12"
            >
              <v-img
                class="mx-auto"
                src="/tpecmagazine.png"
                width="125%"
              /> <br>
              <v-card-text
                class="text-center"
              >
                Some pages of columns from the TPEC 2021 Magazine.
              </v-card-text>
            </v-col>
            <v-col
              lg="6"
              cols="12"
            >
              <v-img
                contain
                class="mx-auto align-center"
                src="/gather.png"
                width="125%"
              /> <br>
              <v-card-text
                class="text-center"
              >
                The Gather Platform was used as a medium with poster sessions, sponsor booths, and even separate rooms to host
                various events, like paper sessions, keynote speakers, and panels featuring experts in a variety of fields.
              </v-card-text>
            </v-col>
          </v-row>
        </base-material-card>
        <base-material-card
          title="TPEC 2020"
          color="#454545"
        >
          <v-row>
            <v-card-text
              class="text-center"
            >
              <font
                size="4"
              >
                TPEC 2020 was held at the Memorial Student Center at Texas A&M University, on February 6th and 7th, 2020.
                With over 200 paper and poster submissions, originating from over 15 countries, TPEC 2020 brought together
                academic and industry representatives to discuss the latest research in the power industry. For TPEC 2020,
                I served as a co-director of the conference, handling all the high-level logistical details of planning
                a conference, including gathering a cast of committee members, and ensuring that our planning processes
                were well documented for the success of future conferences. For more information on TPEC 2020, please visit
                <a
                  href="http://tpec.engr.tamu.edu/2020-tpec-summary/"
                  target="_blank"
                >
                  http://tpec.engr.tamu.edu/2020-tpec-summary/.
                </a>
              </font>
            </v-card-text>
          </v-row>
          <v-row>
            <v-col
              lg="12"
              cols="12"
            >
              <v-img
                class="mx-auto"
                src="https://i.imgur.com/WBotXhT.png"
                width="125%"
              />
            </v-col>
          </v-row>
        </base-material-card>
        <base-material-card
          title="TPEC 2019"
          color="#454545"
        >
          <v-row>
            <v-card-text
              class="text-center"
            >
              <font
                size="4"
              >
                TPEC 2019 was held at the Memorial Student Center at Texas A&M University, on February 7th and 8th, 2019.
                TPEC 2019 had over 220 attendees, almost half of which were from outside the stte of Texas, forming a
                diverse group of students, faculty, and industry representatives to collaborate and talk about the current
                and hot trends in the power and energy industry. For TPEC 2019, I was a committee member, helping to look
                for financial sponsors, and assisting with the behind the scene tasks necessary in helping to ensure the
                conference ran as smooth as possible. For more information about TPEC 2019, please visit
                <a
                  href="http://tpec.engr.tamu.edu/2019-tpec-summary/"
                  target="_blank"
                >
                  http://tpec.engr.tamu.edu/2019-tpec-summary/.
                </a>
              </font>
            </v-card-text>
          </v-row>
          <v-row>
            <v-col
              lg="12"
              cols="12"
            >
              <v-img
                class="mx-auto"
                src="https://i.imgur.com/NWrYrSx.png"
                width="125%"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Homepage',
  }
</script>
